import { PAGE_SIZE } from 'common/constants';
import type { Dispatch, RefObject, SetStateAction } from 'react';
import { useEffect, useCallback } from 'react';

interface useInfiniteFetchOptionsProps {
  loaderRef: RefObject<HTMLElement>;
  setLimit: Dispatch<SetStateAction<number>>;
}

export const useSetInfiniteScrollLimit = ({
  loaderRef,
  setLimit
}: useInfiniteFetchOptionsProps) => {
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];

      if (target.isIntersecting) {
        setLimit((prev) => prev + PAGE_SIZE);
      }
    },
    [setLimit]
  );

  useEffect(() => {
    const loader = loaderRef.current;
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0.25
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader) {
      observer.observe(loader);
    }

    return () => {
      if (loader) {
        observer.unobserve(loader);
      }
    };
  }, [handleObserver, loaderRef]);
};
