import { getTiresReplacementsApi } from 'api/tires-replacements';
import { PAGE_SIZE } from 'common/constants';
import { AppRoute } from 'common/enums/app/app-route';
import { HistoryContainer } from 'components/history-container';
import { HistoryItem } from 'components/history-item';
import { Loader } from 'components/loader';
import { Navbar } from 'components/navbar';
import { NoData } from 'components/no-data';
import { Pagination } from 'components/paginations';
import { Search } from 'components/search';
import { Subheader } from 'components/subheader';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { getReplacementsHistoryAction } from 'store/tires-replacements-history/actions';

export const TiresReplacementsHistoryPage = () => {
  const dispatch = useAppDispatch();
  const { replacementsHistory, isLoading } = useTypedSelector(
    (state) => state.replacementsHistory
  );
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getReplacementsHistoryAction(`?page=${page || 1}`));
    dispatch(getServiceAllUpdatesAction());
  }, [dispatch, searchParams]);

  return (
    <>
      <Navbar>
        <Search
          fetcher={getTiresReplacementsApi}
          rootPath={AppRoute.TIRES_REPLACEMENTS}
        />
      </Navbar>
      <Subheader title="Історія" backRoute={AppRoute.TIRES_REPLACEMENTS}>
        {!!serviceUpdates.results.length &&
          !!replacementsHistory.results.length && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                serviceUpdates.results[0].service_replacements_table
              )}
            </UpdatedAt>
          )}
      </Subheader>
      {isLoading ? (
        <Loader isBig position="static" />
      ) : replacementsHistory.results.length ? (
        <HistoryContainer
          withPagination={replacementsHistory.count > PAGE_SIZE}
        >
          {replacementsHistory.results.map((item, i) => (
            <HistoryItem
              key={i}
              title={item.history_message}
              date={item.confirmed_at}
            />
          ))}
        </HistoryContainer>
      ) : (
        <NoData height="calc(100vh - 162px)" />
      )}
      {replacementsHistory.count > PAGE_SIZE ? (
        <Pagination count={replacementsHistory.count} />
      ) : (
        ''
      )}
    </>
  );
};
