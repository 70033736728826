import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { FleetTiresReplacements } from 'models/fleet-tires-replacements';
import { http } from 'services/http';

export const getFleetTiresReplacementsApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetTiresReplacements>>(
    `${ApiPath.FLEET_TIRES_REPLACEMENTS}${params || ''}`
  );

export const getVehiclePlateOptionsApi = async (params?: string) => {
  try {
    const res = await http.get<ResponseWithPagination<FleetTiresReplacements>>(
      `${ApiPath.FLEET_TIRES_REPLACEMENTS}${params || ''}`
    );

    return {
      options: res.results.map((item) => ({
        id: item.id,
        title: item.vehicle_plate
      })),
      count: res.count
    };
  } catch (err) {
    return { options: [], count: 0 };
  }
};

export const updateFleetTiresReplacementsApi = ({
  id,
  body
}: {
  id: ID;
  body: Record<string, unknown>;
}) =>
  http.patch<FleetTiresReplacements>({
    url: `${ApiPath.FLEET_TIRES_REPLACEMENTS}${id}/update-replacement-tires/`,
    options: {
      body
    }
  });
