export enum ApiPath {
  ROOT = '/',
  AUTH_REFRESH_TOKEN = '/auth/jwt/refresh/',
  AUTH_JWT_CREATE = '/auth/jwt/create/',
  AUTH_USER_ME = '/auth/users/me/',
  COMPANIES = '/companies/',
  COMPANIES_IMPORT = '/companies-import/',
  COMPANIES_EXPORT = '/companies-export/',
  TIRES_MOVEMENTS = '/tires-movements/',
  TIRES_REPLACEMENTS = '/tires-replacements/',
  FLEET_TIRES_REPLACEMENTS = '/fleet-tires-replacements/',
  FLEET_TIRES_PURCHASES = '/fleet-tires-purchases/',
  PRINT_LABELS = '/print-labels/',
  SERVICE_TABLES_LAST_UPDATE = '/service-tables-last-update/',
  AVIS_CONTACTS = '/avis-contacts/',
  AVIS_CONTACT_MANAGERS = '/avis-contact-managers/',
  TIRES_MOVEMENTS_HISTORY = '/tires-movements-history/',
  TIRES_REPLACEMENTS_HISTORY = '/tires-replacements-history/',
  TIRES_DISPOSALS_HISTORY = '/tires-disposals-history/',
  FLEET_REPLACEMENTS_HISTORY = '/fleet-tires-replacements-history',
  FLEET_PURCHASES_HISTORY = '/fleet-tires-purchases-history/',
  FLEET_VEHICLE = '/fleet-vehicle/',
  FLEET_VEHICLE_CONFIRMED_UPDATE = '/fleet-vehicle/confirmed-updates/',
  TIRES_DISPOSALS = '/tires-disposals/',
  REPORTS = '/report-files/',
  SERVICE_ALL_UPDATES = '/service-all-updates/',
  FLEET_ALL_UPDATES = '/fleet-all-updates/',
  FLEET_NOTIFICATIONS = '/fleet-notifications/',
  FLEET_NOTIFICATIONS_SETTINGS = '/fleet-notification-settings/',
  FLEET_NOTIFICATIONS_READ_ALL = '/fleet-notifications-read-all/',
  TIRES_STORAGE = '/tires-storages/',
  LOCATIONS = '/locations/'
}
