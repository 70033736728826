import type { ReactNode } from 'react';
import { EditableCellStyled } from './style';

interface EditableCellProps {
  children: ReactNode;
  disabled?: boolean;
  withOutPaddingTop?: boolean;
  invalid?: boolean;
  isTouched?: boolean;
}

export const EditableCell = ({
  children,
  disabled,
  withOutPaddingTop,
  invalid,
  isTouched
}: EditableCellProps) => (
  <EditableCellStyled
    $withOutPaddingTop={withOutPaddingTop}
    $disabled={disabled}
    $invalid={invalid}
    $isTouched={isTouched}
  >
    {children}
  </EditableCellStyled>
);
