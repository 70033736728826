import styled from 'styled-components';

export const TableSelectOptionsStyled = styled.div<{
  $openTop?: boolean;
  $isFilter?: boolean;
}>`
  min-width: ${(p) => (p.$isFilter ? '207px' : 'unset')};
  padding: 0 0 8px;
  position: absolute;
  max-height: 264px;
  overflow: auto;
  left: 0;
  top: ${(p) =>
    p.$isFilter
      ? 'calc(100% + 5px)'
      : p.$openTop
      ? 'unset'
      : 'calc(100% - 8px)'};
  bottom: ${(p) => (p.$openTop ? 'calc(100% - 8px)' : 'unset')};
  width: 100%;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  background: ${(p) => p.theme.colors.white};
  box-shadow:
    4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  z-index: 1;
`;

export const OptionsItem = styled.div`
  padding: 10px;
  color: ${(p) => p.theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  background: ${(p) => p.theme.colors.white};
  &:hover {
    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.colors.primary};
  }
  text-transform: none;
  cursor: pointer;
`;

export const SearchWrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 8px;
  background: ${(p) => p.theme.colors.white};
`;

export const SearchStyled = styled.div<{ $isActive?: boolean }>`
  position: relative;
  padding: 8px;
  height: 32px;
  border: 1px solid ${(p) => (p.$isActive ? '#306FE3' : p.theme.colors.stroke)};
  border-radius: 6px;
  transition: all 0.2s;
`;

export const SearchIcon = styled.div`
  position: absolute;
  right: 8px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  color: ${(p) => p.theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
  background: transparent;
`;

export const CloseIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  right: 8px;
  top: calc(50% - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    max-width: 100%;
  }
`;

export const NoDataText = styled.p`
  padding: 8px;
  color: ${(p) => p.theme.colors.black};
  text-transform: none;
`;
