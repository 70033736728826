import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  confirmedUpdateFleetVehicleApi,
  getFleetVehicleApi,
  updateFleetVehicleApi
} from 'api/fleet-vehicle';
import type { ID } from 'common/types';

export enum FleetVehicleAction {
  GET_FLEET_VEHICLE = 'fleet-vehicle/get-fleet-vehicle',
  UPDATE_FLEET_VEHICLE = 'fleet-vehicle/update-fleet-vehicle',
  CONFIRMED_UPDATED = 'fleet-vehicle/confirmed-update'
}

export const getFleetVehicleAction = createAsyncThunk(
  FleetVehicleAction.GET_FLEET_VEHICLE,
  getFleetVehicleApi
);

export const updateFleetVehicleAction = createAsyncThunk(
  FleetVehicleAction.UPDATE_FLEET_VEHICLE,
  updateFleetVehicleApi
);

export const confirmedUpdateFleetVehicleAction = createAsyncThunk(
  FleetVehicleAction.CONFIRMED_UPDATED,
  async (
    body: {
      body: {
        id: ID;
        driver_first_name?: string;
        driver_last_name?: string;
        driver_phone?: string;
        location?: ID;
        tires_storage?: ID;
      }[];
    },
    { rejectWithValue }
  ) => {
    try {
      return await confirmedUpdateFleetVehicleApi(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
