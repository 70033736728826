import { useEffect, useState } from 'react';
import type { OptionItem, OptionsFetcher } from 'common/types';
import { PAGE_SIZE } from 'common/constants';
import { useSearch } from './use-search';

export const useGetInfiniteOptions = ({
  fetcher
}: {
  fetcher: OptionsFetcher;
}) => {
  const { term, searchTerm, onChangeSearch } = useSearch();
  const [hasOptions, setHasMoreOptions] = useState(true);
  const [options, setOptions] = useState<OptionItem[]>([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const options = await fetcher(`?limit=${limit}&search=${searchTerm}`);
        setOptions(options);
        setHasMoreOptions(limit <= options.length);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [fetcher, limit, searchTerm]);

  return {
    search: {
      term,
      searchTerm,
      onChangeSearch
    },
    hasMoreOptions: hasOptions,
    options,
    setLimit,
    isLoading
  };
};
