import styled from 'styled-components';

export const TooltipStyled = styled.div`
  position: absolute;
  left: 50%;
  bottom: calc(100% + 18px);
  width: 242px;
  transform: translateX(-50%);
  border-radius: 5px;
  background: ${(p) => p.theme.colors.secondaryDark};
  padding: 10px;
  color: ${(p) => p.theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  z-index: 4;
  text-align: center;
  &:after {
    content: '';
    width: 20px;
    height: 20px;
    left: calc(50% - 10px);
    transform: rotate(45deg);
    background: ${(p) => p.theme.colors.secondaryDark};
    position: absolute;
    bottom: -6px;
    z-index: -1;
  }
`;
