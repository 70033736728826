import { BtnSubmit } from 'components/btn-submit';
import type { FC } from 'react';
import { ConfirmPanelStyled } from './style';

interface ConfirmPanelProps {
  hasPagination: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const ConfirmPanel: FC<ConfirmPanelProps> = ({
  hasPagination,
  disabled,
  onClick
}) => (
  <ConfirmPanelStyled $hasPagination={hasPagination}>
    <BtnSubmit onClick={onClick} disabled={disabled}>
      Підтвердити зміни
    </BtnSubmit>
  </ConfirmPanelStyled>
);
