import { SidebarLogoSvg } from 'components/svg/sidebar-logo-svg';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { AppRoute } from 'common/enums/app/app-route';
import { useMemo, useRef } from 'react';
import { CloseMenuSvg } from 'components/svg/close-menu-svg';
import { toggleMenu } from 'store/menu/reducer';
import { useOutsideClick } from 'hooks/use-outside-click';
import { useTheme } from 'styled-components';
import { logOut } from 'store/user/reducer';
import { storage } from 'services/storage';
import { useLocation } from 'react-router-dom';
import { Search } from 'components/search';
import { getTiresMovementsApi } from 'api/tires-movements';
import { getTiresReplacementsApi } from 'api/tires-replacements';
import { getTiresDisposalsApi } from 'api/tires-disposals';
import {
  CloseMenu,
  Exit,
  Logo,
  Menu,
  MenuWrapper,
  SearchMobWrapper,
  SidebarStyled
} from './style';
import { MenuItem } from './menu-item';

export const Sidebar = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.user);
  const notif = useTypedSelector((state) => state.notifications);
  const theme = useTheme();
  const { showMenu } = useTypedSelector((state) => state.menu);
  useOutsideClick(ref, () => {
    if (window.innerWidth <= +theme.breakpoints.sm.slice(0, -2) && showMenu) {
      dispatch(toggleMenu());
    }
  });

  const generateMenu = useMemo(() => {
    if (!user?.role) {
      return [];
    }
    switch (user.role.id) {
      case 3:
        return [{ path: AppRoute.ROOT, title: 'Дані користувачів' }];
      case 1:
        return [
          {
            path: AppRoute.ROOT,
            title: 'Переміщення шин',
            tag: 'movements',
            notifTag: 'serviceMovements'
          },
          {
            path: AppRoute.TIRES_REPLACEMENTS,
            title: 'Зміна шин',
            tag: 'replacements',
            notifTag: 'serviceReplacements'
          },
          {
            path: AppRoute.UTILIZATION,
            title: 'Утилізація',
            tag: 'disposals',
            notifTag: 'serviceDisposals'
          },
          { path: AppRoute.REMAINING, title: 'Залишки на СТО' }
        ];
      case 2:
        return [
          {
            path: AppRoute.FLEET_AUTO,
            title: 'Авто',
            tag: 'fleet-auto',
            notifTag: 'fleetVehicle'
          },
          {
            path: AppRoute.ROOT,
            title: 'Заміна / Утилізація',
            tag: 'fleet-replacements',
            notifTag: 'fleetReplacements'
          },
          // {
          //   path: AppRoute.FLEET_TIRES_PURCHASASES,
          //   title: 'Підтвердження закупівлі',
          //   tag: 'fleet-purchases',
          //   notifTag: 'fleetPurchases'
          // },
          {
            path: AppRoute.FLEET_CONNECT,
            title: 'Зв’язатись з AVIS',
            tag: 'fleet-connect'
          }
        ];
      default:
        return [];
    }
  }, [user]);

  const renderSearch = () => {
    if (
      window.innerWidth <= +theme.breakpoints.sm.slice(0, -2) &&
      user?.role.id === 1 &&
      pathname.indexOf(AppRoute.REMAINING) === -1
    ) {
      if (pathname.indexOf('movements') > -1 || pathname === AppRoute.ROOT) {
        return (
          <SearchMobWrapper>
            <Search fetcher={getTiresMovementsApi} rootPath={AppRoute.ROOT} />
          </SearchMobWrapper>
        );
      }
      if (pathname.indexOf('replacement') > -1) {
        return (
          <SearchMobWrapper>
            <Search
              fetcher={getTiresReplacementsApi}
              rootPath={AppRoute.TIRES_REPLACEMENTS}
            />
          </SearchMobWrapper>
        );
      }
      if (pathname.indexOf('disposals') > -1) {
        return (
          <SearchMobWrapper>
            <Search
              fetcher={getTiresDisposalsApi}
              rootPath={AppRoute.UTILIZATION}
            />
          </SearchMobWrapper>
        );
      }

      return null;
    }

    return null;
  };

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
  };

  const handleExit = () => {
    dispatch(logOut());
    storage.logOut();
  };

  return (
    <SidebarStyled ref={ref} $show={showMenu}>
      <Logo>
        <SidebarLogoSvg />
      </Logo>
      <MenuWrapper>
        <Menu>
          {generateMenu.map((item) => (
            <MenuItem
              key={item.path}
              path={item.path}
              title={item.title}
              tag={item.tag}
              showNotif={
                !!(
                  item.notifTag &&
                  Object.entries(notif)
                    .filter(([_, value]) => value)
                    .some(([key]) => key === item.notifTag)
                )
              }
            />
          ))}
        </Menu>
        <Exit onClick={handleExit}>Вихід</Exit>
        {renderSearch()}
      </MenuWrapper>
      <CloseMenu onClick={handleToggleMenu}>
        <CloseMenuSvg />
      </CloseMenu>
    </SidebarStyled>
  );
};
