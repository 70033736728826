import { useRef, useState } from 'react';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import type { OptionItem } from 'common/types';
import {
  OptionsItem,
  TableSelectOptions,
  TableSelectStyled,
  TableSelectWrapper
} from './style';

interface TableSelectProps {
  openTop?: boolean;
  initialValue: OptionItem | null;
  onSelect: (value: OptionItem) => void;
  options: OptionItem[];
  disabled?: boolean;
  withoutBg?: boolean;
}

export const TableSelect = ({
  openTop,
  initialValue,
  onSelect,
  options,
  disabled,
  withoutBg
}: TableSelectProps) => {
  const ref = useRef(null);
  const [showOtions, setShowOptions] = useState(false);
  useOutsideClick(ref, () => setShowOptions(false));

  const handleSelect = (item: OptionItem) => {
    onSelect(item);
    setShowOptions(false);
  };

  return (
    <TableSelectWrapper ref={ref}>
      <TableSelectStyled
        onClick={() => setShowOptions((state) => !state)}
        $isOpen={showOtions}
        $disabled={disabled}
        $withoutBg={withoutBg}
      >
        {initialValue?.title || '-'}
        {!disabled && <ArrowSvg />}
      </TableSelectStyled>
      {showOtions && !disabled && (
        <TableSelectOptions $openTop={openTop}>
          {options.map((item) => (
            <OptionsItem key={item.id} onClick={() => handleSelect(item)}>
              {item.title || '-'}
            </OptionsItem>
          ))}
        </TableSelectOptions>
      )}
    </TableSelectWrapper>
  );
};
