import PhoneInput from 'react-phone-input-2';
import type { FC } from 'react';
import { validatePhone } from 'helpers/validate-phone';
import { InputPhoneStyled } from './style';
import 'react-phone-input-2/lib/style.css';

interface InputPhoneProps {
  value: string;
  setValue: (value: string) => void;
  validate?: boolean;
  isTouched?: boolean;
}

export const InputPhone: FC<InputPhoneProps> = ({
  value,
  setValue,
  validate,
  isTouched
}) => (
  <InputPhoneStyled
    $isTouched={isTouched}
    $invalid={validate && !validatePhone(value)}
  >
    <PhoneInput
      country="ua"
      value={value}
      onChange={setValue}
      preferredCountries={['ua']}
      placeholder="+380 (__) ___ __ __"
    />
  </InputPhoneStyled>
);
