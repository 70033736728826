import { ApiPath } from 'common/enums/http/api-path';
import type { OptionItem } from 'common/types';
import type { TiresStorage } from 'models/titres-storage';
import { http } from 'services/http';

export const getTiresStoragesApi = (params: string) =>
  http.get<TiresStorage[]>(`${ApiPath.TIRES_STORAGE}${params}`);

export const getLocationsApi = (params: string) =>
  http.get<OptionItem[]>(`${ApiPath.LOCATIONS}${params}`);
