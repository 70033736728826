import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import type { FleetVehicle } from 'models/fleet-vehicle';

interface DriverNameProps {
  value: string;
  id: ID;
  disabled?: boolean;
  field: keyof FleetVehicle;
  isTouched?: boolean;
  setValue: (value: string) => void;
  validate: boolean;
}

export const DriverName = ({
  value,
  disabled,
  isTouched,
  validate,
  setValue
}: DriverNameProps) => (
  <EditableCell
    invalid={validate && !value}
    isTouched={isTouched}
    withOutPaddingTop
    disabled={disabled}
  >
    <CellInput value={value} setValue={setValue} />
  </EditableCell>
);
