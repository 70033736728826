import { useCallback, useState } from 'react';
import { useDebounce } from './use-debounce';

export const useSearch = () => {
  const [term, setTerm] = useState('');
  const [termDebounced, setTermDebounced] = useState('');
  const debounce = useDebounce(setTermDebounced, 500);

  const handleChangeSearch = useCallback(
    (value: string) => {
      setTerm(value);
      debounce(value);
    },
    [debounce]
  );

  return {
    term,
    onChangeSearch: handleChangeSearch,
    searchTerm: termDebounced
  };
};
