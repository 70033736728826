export const FilterSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1488_157805)">
      <path
        d="M14.471 0H1.52891C0.925484 0 0.570774 0.680077 0.916515 1.17494C0.923171 1.18438 0.711645 0.896321 5.85489 7.89718C5.97026 8.06411 6.03123 8.25945 6.03123 8.46244V15.2097C6.03123 15.8658 6.78337 16.2277 7.29339 15.8418L9.48564 14.1892C9.78814 13.9646 9.96863 13.6058 9.96863 13.2289V8.46244C9.96863 8.25945 10.0296 8.06411 10.145 7.89718C15.2842 0.901696 15.0767 1.18431 15.0833 1.17494C15.4289 0.680295 15.0746 0 14.471 0ZM9.38583 7.34703C9.17331 7.63631 9.03115 8.03964 9.03115 8.46241V13.2288C9.03115 13.3105 8.99194 13.3883 8.92631 13.4368C8.8686 13.4793 9.30574 13.1508 6.96871 14.9125V8.46244C6.96871 8.06477 6.8483 7.68221 6.62053 7.35616C6.61487 7.34807 6.77359 7.56425 3.39742 2.96871H12.6024L9.38583 7.34703ZM13.2911 2.0312H2.70872L1.90521 0.937476H14.0947L13.2911 2.0312Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1488_157805">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
