import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  FleetTire,
  FleetTiresReplacements
} from 'models/fleet-tires-replacements';
import type { ID, ResponseWithPagination } from 'common/types';
import { getApiError } from 'helpers/get-api-error';
import {
  getFleetTiresReplacementsAction,
  updateFleetTiresReplacementsAction
} from './actions';

interface TiresReplacementState {
  fleetReplacements: ResponseWithPagination<FleetTiresReplacements>;
  isLoading: boolean;
  error: string;
}

const initialState: TiresReplacementState = {
  fleetReplacements: { count: 0, next: null, previous: null, results: [] },
  isLoading: false,
  error: ''
};

const fleetTiresReplacementsSlice = createSlice({
  name: 'fleet-replacements',
  initialState,
  reducers: {
    updateFleetReplacement: (
      state,
      { payload }: PayloadAction<{ tire: FleetTire; vehicleId: ID }>
    ) => {
      state.fleetReplacements.results = state.fleetReplacements.results.map(
        (item) =>
          item.id === payload.vehicleId
            ? {
                ...item,
                tire_replacements: item.tire_replacements.map((tire) =>
                  tire.id === payload.tire.id ? payload.tire : tire
                )
              }
            : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFleetTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.fleetReplacements = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetTiresReplacementsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetTiresReplacementsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFleetTiresReplacementsAction.pending, (state) => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(
      updateFleetTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.fleetReplacements.results = state.fleetReplacements.results.map(
          (item) => (item.id === payload.id ? payload : item)
        );
        state.error = '';
        state.isLoading = false;
      }
    );
    builder.addCase(
      updateFleetTiresReplacementsAction.rejected,
      (state, { error }) => {
        state.error = getApiError(error);
        state.isLoading = false;
      }
    );
  }
});

export const { updateFleetReplacement } = fleetTiresReplacementsSlice.actions;
export const fleetTiresReplacementsReducer =
  fleetTiresReplacementsSlice.reducer;
