import { DataTable } from 'components/data-table';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import {
  getTiresMovementsAction,
  updateTiresMovementsAction
} from 'store/tires-movements.ts/actions';
import type { GroupedMovements } from 'store/tires-movements.ts/helpers';
import { groupedMovements } from 'store/tires-movements.ts/helpers';
import { CheckBox } from 'components/checkbox';
import { Subheader } from 'components/subheader';
import { HeaderBtn } from 'components/header-btn';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoute } from 'common/enums/app/app-route';
import { Navbar } from 'components/navbar';
import { Search } from 'components/search';
import { EditableCell } from 'components/editable-cell';
import {
  dateTableFormat,
  dateToApiFormat,
  dateToHeaderFormat
} from 'helpers/date';
import type { TiresMovements } from 'models/tires-movements';
import { getTiresMovementsApi } from 'api/tires-movements';
import { NoData } from 'components/no-data';
import { UpdatedAt } from 'components/updated-at';
import { SubheaderActions } from 'components/subheader/subheader-actions';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { updateNotif } from 'store/notifications/reducer';
import { Loader } from 'components/loader';
import { PAGE_SIZE } from 'common/constants';
import type { MovementsTable } from './data';
import { tableHeaders } from './data';
import { ServiceComment } from './service-comment';
import { Calendar } from './calendar';

export const TiresMovementsPage = () => {
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.user);
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { scrollHeight } = useScrollHeight(tableRef);
  const { movements, isLoading } = useTypedSelector(
    (state) => state.tiresMovements
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const queryString = `page=${page || 1}&start_date=${
      startDate ? dateToApiFormat(startDate) : ''
    }&end_date=${endDate ? dateToApiFormat(endDate) : ''}`;
    dispatch(
      getTiresMovementsAction(
        search ? `?search=${search}&${queryString}` : `?${queryString}`
      )
    );
    dispatch(updateNotif({ serviceMovements: false }));
    dispatch(getServiceAllUpdatesAction());
  }, [dispatch, searchParams, startDate, endDate]);

  const handleConfirmed = (item: TiresMovements) => {
    const isSender =
      user?.company_id === item.service_station_sender?.company_id;
    const body = isSender
      ? {
          sender_comment: item.sender_comment,
          confirmed_by_sender: true
        }
      : {
          receiver_comment: item.receiver_comment,
          confirmed_by_receiver: true
        };

    dispatch(
      updateTiresMovementsAction({
        id: item.id,
        body
      })
    );
  };

  const transformToTableFormat = (movements: GroupedMovements[]) =>
    movements.map((item) => {
      const isSender =
        user?.company_id === item.service_station_sender?.company_id;

      return {
        ...item,
        tire_model: item.isHeader ? '' : item.tire_model,
        vehicle_plate: item.isHeader ? '' : item.vehicle_plate,
        scheduled_moving_date: item.isHeader
          ? ''
          : dateTableFormat(item.scheduled_moving_date),
        scheduled_replacement_date: item.isHeader
          ? ''
          : dateTableFormat(item.scheduled_replacement_date),
        service_comment: item.isHeader ? (
          ''
        ) : (
          <ServiceComment
            disabled={
              isSender ? item.confirmed_by_sender : item.confirmed_by_receiver
            }
            id={item.id}
            value={isSender ? item.sender_comment : item.receiver_comment}
            serviceStationSender={item.service_station_sender}
          />
        ),
        confirmed: item.isHeader ? (
          ''
        ) : (
          <EditableCell
            disabled={
              isSender ? item.confirmed_by_sender : item.confirmed_by_receiver
            }
          >
            <CheckBox
              disabled={
                isSender ? item.confirmed_by_sender : item.confirmed_by_receiver
              }
              checked={
                isSender ? item.confirmed_by_sender : item.confirmed_by_receiver
              }
              setChecked={() => handleConfirmed(item)}
            />
          </EditableCell>
        ),
        confirmed_at: item.isHeader
          ? ''
          : isSender
          ? dateTableFormat(item.confirmed_by_sender_at)
          : dateTableFormat(item.confirmed_by_receiver_at),
        direction: item.isHeader ? (isSender ? 'З СТО' : 'На СТО') : '',
        doc_number: item.isHeader ? item.doc_number : '',
        carrier: item.isHeader ? item.carrier : ''
      };
    });

  return (
    <>
      <Navbar>
        <Search fetcher={getTiresMovementsApi} rootPath={AppRoute.ROOT} />
      </Navbar>
      <Subheader title="Переміщення шин">
        {!!serviceUpdates.results.length && !!movements.results.length && (
          <UpdatedAt>
            {dateToHeaderFormat(
              serviceUpdates.results[0].service_movements_table
            )}
          </UpdatedAt>
        )}
        <SubheaderActions>
          <Calendar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <HeaderBtn onClick={() => navigate(AppRoute.MOVEMENTS_HISTORY)}>
            Переглянути історію
          </HeaderBtn>
        </SubheaderActions>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {movements.results.length ? (
          <DataTable<MovementsTable>
            headers={tableHeaders}
            data={transformToTableFormat(groupedMovements(movements.results))}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              movements.count >= PAGE_SIZE ? scrollHeight - 64 : scrollHeight
            }
            count={movements.count}
            isFetching={isLoading}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
