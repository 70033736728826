import type { OptionItem } from 'common/types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import { TableSelectOptions } from 'components/table-select-options';
import { TableSelectStyled, TableSelectWrapper } from './style';

interface SelectServerDataProps {
  openTop?: boolean;
  initialValue: OptionItem | null;
  onSelect: (value: OptionItem) => void;
  searchTerm: string;
  onChangeSearch: (value: string) => void;
  options: OptionItem[];
  disabled?: boolean;
  withoutBg?: boolean;
  setLimit: Dispatch<SetStateAction<number>>;
  hasMoreOptions: boolean;
  isLoading: boolean;
  validate?: boolean;
  isTouched?: boolean;
}

export const SelectServerData: FC<SelectServerDataProps> = ({
  initialValue,
  onSelect,
  searchTerm,
  onChangeSearch,
  options,
  disabled,
  withoutBg,
  setLimit,
  hasMoreOptions,
  isLoading,
  validate,
  isTouched
}) => {
  const dropdounRef = useRef<HTMLDivElement>(null);
  const [openTop, setOpenTop] = useState(false);
  const [showOtions, setShowOptions] = useState(false);
  useOutsideClick(dropdounRef, () => {
    setShowOptions(false);
  });

  const handleSelect = (item: OptionItem) => {
    onSelect(item);
    setShowOptions(false);
    onChangeSearch('');
  };

  const toggleSelect = () => {
    setShowOptions((state) => {
      if (!state && dropdounRef.current) {
        if (dropdounRef.current) {
          const minOffset = window.innerHeight >= 700 ? 250 : 150;
          setOpenTop(
            window.innerHeight -
              dropdounRef.current.getBoundingClientRect().top <
              minOffset && window.innerHeight > 600
          );
        }
      }

      return !state;
    });
  };

  return (
    <TableSelectWrapper ref={dropdounRef}>
      <TableSelectStyled
        onClick={toggleSelect}
        $isOpen={showOtions}
        $disabled={disabled}
        $withoutBg={withoutBg}
        $invalid={validate && !initialValue?.title}
        $isTouched={isTouched}
      >
        {initialValue?.title || ''}
        {!disabled && <ArrowSvg />}
      </TableSelectStyled>
      {showOtions && !disabled && (
        <TableSelectOptions
          options={options}
          onSelect={handleSelect}
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          openTop={openTop}
          setLimit={setLimit}
          hasMoreOptions={hasMoreOptions}
          isLoading={isLoading}
        />
      )}
    </TableSelectWrapper>
  );
};
