import { ApiPath } from 'common/enums/http/api-path';
import type { ID, OptionItem, ResponseWithPagination } from 'common/types';
import type { FleetVehicle } from 'models/fleet-vehicle';
import { http } from 'services/http';

export const getFleetVehicleApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetVehicle>>(
    `${ApiPath.FLEET_VEHICLE}${params || ''}`
  );

export const getFleetVehicleFiltersStringApi = async (
  params: string,
  key: keyof FleetVehicle
): Promise<{ options: OptionItem[]; count: number }> => {
  try {
    const res = await getFleetVehicleApi(params);

    return {
      options: (
        Array.from(
          new Set(
            res.results
              .map((item) => (typeof item[key] === 'string' ? item[key] : ''))
              .filter((item) => item)
          )
        ) as string[]
      ).map((item) => ({ id: item, title: item })),
      count: res.count
    };
  } catch (err) {
    return { options: [], count: 0 };
  }
};

export const getFleetVehicleFiltersLocationsApi = async (
  params: string
): Promise<{ options: OptionItem[]; count: number }> => {
  try {
    const res = await getFleetVehicleApi(params);

    const uniqueItemsMap = new Map<ID, OptionItem>();

    res.results.forEach((item) => {
      if (
        item.vehicle_location?.title &&
        !uniqueItemsMap.has(item.vehicle_location.id)
      ) {
        uniqueItemsMap.set(item.vehicle_location.id, item.vehicle_location);
      }
    });

    return { options: Array.from(uniqueItemsMap.values()), count: res.count };
  } catch (err) {
    return { options: [], count: 0 };
  }
};

export const getFleetVehicleFiltersStorageApi = async (
  params: string
): Promise<{ options: OptionItem[]; count: number }> => {
  try {
    const res = await getFleetVehicleApi(params);

    const uniqueItemsMap = new Map<ID, OptionItem>();

    res.results.forEach((item) => {
      if (
        item.tires_storage_city?.title &&
        !uniqueItemsMap.has(item.tires_storage_city.id)
      ) {
        uniqueItemsMap.set(item.tires_storage_city.id, item.tires_storage_city);
      }
    });

    return { options: Array.from(uniqueItemsMap.values()), count: res.count };
  } catch (err) {
    return { options: [], count: 0 };
  }
};

export const updateFleetVehicleApi = ({
  id,
  body
}: {
  id: ID;
  body: Partial<Record<keyof FleetVehicle, unknown>>;
}) =>
  http.patch<FleetVehicle>({
    url: `${ApiPath.FLEET_VEHICLE}${id}/`,
    options: {
      body
    }
  });

export const confirmedUpdateFleetVehicleApi = ({
  body
}: {
  body: {
    id: ID;
    driver_first_name?: string;
    driver_last_name?: string;
    driver_phone?: string;
    location?: ID;
    tires_storage?: ID;
  }[];
}) =>
  http.patch<FleetVehicle>({
    url: ApiPath.FLEET_VEHICLE_CONFIRMED_UPDATE,
    options: {
      body
    }
  });
