import { ExportImport } from 'components/export-import';
import { DataTable } from 'components/data-table';
import { useEffect, useRef, useState } from 'react';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { Popup } from 'components/popup';
import { ConfirmPopup } from 'components/confirm-popup';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { fetchCompanies, updateCompanyAction } from 'store/companies/actions';
import { generatePassword } from 'helpers/generate-password';
import type { Company } from 'models/company';
import { Subheader } from 'components/subheader';
import { HeaderBtn } from 'components/header-btn';
import { PlusSvg } from 'components/svg/plus-svg';
import { Navbar } from 'components/navbar';
import { deleteCompanies } from 'api/companies';
import { NoData } from 'components/no-data';
import { TableSelect } from 'components/table-select';
import { updateRole } from 'store/companies/reducer';
import type { ID, OptionItem } from 'common/types';
import { Loader } from 'components/loader';
import { useSearchParams } from 'react-router-dom';
import { PAGE_SIZE } from 'common/constants';
import { GeneratePassword, SubheaderActions, TableWrapper } from './style';
import { tableHeaders } from './data';
import { AddUser } from './add-user';

export const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { companies, isLoading } = useTypedSelector((state) => state.companies);
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(fetchCompanies(page || '1'));
  }, [dispatch, searchParams]);

  const handleGeneratePassword = (company: Company) => {
    const newPassword = generatePassword(12);
    dispatch(
      updateCompanyAction({
        id: company.id,
        data: {
          password_text: newPassword,
          company_role: company.company_role.id
        }
      })
    );
  };

  const handleDelete = () => {
    deleteCompanies(checkedList)
      .then(() => {
        dispatch(fetchCompanies());
        setConfirmDelete(false);
        setCheckedList([]);
      })
      .catch(console.log);
  };

  const handleSelectRole = (role: OptionItem, companyId: ID) => {
    dispatch(updateRole({ companyId, role }));
  };

  return (
    <>
      <Navbar />
      <Subheader title="Дані користувачів">
        <SubheaderActions>
          <HeaderBtn icon={<PlusSvg />} onClick={() => setShowAddUser(true)}>
            Додати компанію
          </HeaderBtn>
          <ExportImport />
        </SubheaderActions>
      </Subheader>
      <TableWrapper ref={tableRef}>
        {companies.results.length ? (
          <DataTable
            headers={tableHeaders}
            data={companies.results.map((item) => ({
              id: item.id,
              company_id: item.company_id,
              login: item.company_name,
              email: item.email,
              password: item.password_text,
              generatePassword: (
                <GeneratePassword onClick={() => handleGeneratePassword(item)}>
                  {item.password_text ? 'Згенерувати Повторно' : 'Згенерувати'}
                </GeneratePassword>
              ),
              role: (
                <TableSelect
                  initialValue={item.company_role}
                  options={[
                    { id: 1, title: 'Кабінет СТО' },
                    { id: 2, title: 'Фліт менеджер' }
                  ]}
                  onSelect={(value) => handleSelectRole(value, item.id)}
                  disabled={!!item.password_text}
                  withoutBg
                />
              )
            }))}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            actions={[
              { title: 'Видалити', handler: () => setConfirmDelete(true) }
            ]}
            customHeight={
              companies.count >= PAGE_SIZE ? scrollHeight - 64 : scrollHeight
            }
            count={companies.count}
            isFetching={isLoading}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </TableWrapper>
      <Popup title="Нова компанія" show={showAddUser} setShow={setShowAddUser}>
        <AddUser setShow={setShowAddUser} />
      </Popup>
      <ConfirmPopup
        title={`Чи дійсно бажаєте видалити ${
          checkedList.length > 1 ? 'компанії' : 'компанію'
        }?`}
        onSubmit={handleDelete}
        show={confirmDelete}
        setShow={setConfirmDelete}
      />
    </>
  );
};
