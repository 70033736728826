import styled from 'styled-components';

export const FilterStyled = styled.div``;

export const Icon = styled.div`
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: grid;
  place-items: center;
  svg {
    &:hover {
      path {
        fill: ${(p) => p.theme.colors.coloumnHover};
      }
    }
    max-width: 100%;
    height: auto;
  }
`;
