import styled from 'styled-components';

interface TableStyledProps {
  checked?: boolean;
}

export const TableWrapper = styled.div<{ customHeight?: string; mt?: string }>`
  margin-top: ${(p) => p.mt || '0'};
  width: 100%;
  overflow-y: auto;
  @supports (overflow-anchor: auto) and (overflow-y: overlay) {
    overflow-y: overlay;
  }
  &::-webkit-scrollbar-track {
    margin-top: 60px;
  }
`;

export const TableActionsWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const TableActions = styled.div`
  padding: 18px 30px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 25px;
  background: ${(p) => p.theme.colors.secondary};
  border-top: 1px solid ${(p) => p.theme.colors.border};
`;

export const TableActionsCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.mainDark};
  min-width: 55px;
  display: flex;
  align-items: center;
`;

export const TableActionsItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.primary};
  cursor: pointer;
`;

export const TableStyled = styled.table<{ $isFetching?: boolean }>`
  width: 100%;
  border-spacing: 0;
  opacity: ${(p) => (p.$isFetching ? '0.6' : '1')};
  pointer-events: ${(p) => (p.$isFetching ? 'none' : 'all')};
`;

export const Row = styled.tr<TableStyledProps>`
  height: 30px;
  transition: background-color 0.2s;
  background-color: ${(p) =>
    p.checked ? p.theme.colors.coloumn : 'transparent'};
`;

export const THead = styled.thead`
  height: 60px;
  ${Row} {
    height: 60px;
  }
`;

export const TBody = styled.tbody<TableStyledProps>`
  background: #ffff;
  overflow: auto;
`;

export const Th = styled.th<{
  $showActions?: boolean;
  $minWidth?: string;
  $isEmpty?: boolean;
  $stickyColumns?: number;
  $maxWidth?: string;
  $nowrap?: boolean;
}>`
  min-width: ${(p) => p.$minWidth};
  max-width: ${(p) => p.$maxWidth || 'unset'};
  background: ${(p) =>
    p.$isEmpty ? p.theme.colors.white : p.theme.colors.secondaryDark};
  border: 0.5px solid
    ${(p) => (p.$isEmpty ? 'transparent' : p.theme.colors.border)};
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
  white-space: ${(p) => (p.$nowrap ? 'nowrap' : 'unset')};
  padding: 0 16px;
  &:first-child {
    width: 45px;
    border-left: none;
    label {
      margin: 0 auto;
    }
    left: ${(p) => (p.$stickyColumns ? 0 : 'unset')};
    z-index: 4;
  }
  &:nth-child(2) {
    left: ${(p) => (p.$stickyColumns ? '100px' : 'unset')};
    z-index: 4;
    ${(p) =>
      p.$stickyColumns
        ? 'box-shadow: 8px 0px 20px 0px rgba(18, 49, 82, 0.13);'
        : ''}
  }
  position: sticky;
  top: ${(p) => (p.$showActions ? '60px' : '0')};
  transition: top 300ms;
  transition-delay: ${(p) => (p.$showActions ? '100ms' : '0')};
  z-index: 3;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    white-space: ${(p) => (p.$maxWidth ? 'wrap' : 'nowrap')};
  }
`;

export const ThContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const Cell = styled.td<{
  $isGeneratePassword?: boolean;
  $isEditable?: boolean;
  $stickyColumns?: number;
  $tableScrolled?: boolean;
}>`
  height: 30px;
  padding: ${(p) => (p.$isEditable ? 0 : '0 16px')};
  border: 0.5px solid
    ${(p) => (p.$isGeneratePassword ? 'transparent' : p.theme.colors.stroke)};
  background: ${(p) => p.theme.colors.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.secondaryDark};
  max-width: 300px;
  ${(p) => (p.$isGeneratePassword ? 'background: white;' : '')}
  &:first-child {
    width: 45px;
    border-left: none;
    label {
      margin: 0 auto;
    }
    position: ${(p) => (p.$stickyColumns ? 'sticky' : 'unset')};
    left: ${(p) => (p.$stickyColumns ? 0 : 'unset')};
    z-index: 2;
  }
  &:nth-child(2) {
    position: ${(p) => (p.$stickyColumns ? 'sticky' : 'unset')};
    left: ${(p) => (p.$stickyColumns ? '100px' : 'unset')};
    z-index: 2;
    ${(p) =>
      p.$stickyColumns && p.$tableScrolled
        ? 'box-shadow: 8px 0px 20px -2px rgba(18, 49, 82, 0.13);'
        : ''}
  }
  /* &:last-child {
    padding-right: 20px;
  } */
`;

export const SubTitle = styled.span`
  display: block;
  color: ${(p) => p.theme.colors.white};
  font-size: 8px;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
`;

export const Sort = styled.div`
  cursor: pointer;
`;
