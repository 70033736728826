export const FilterActiveSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1488_167524)">
      <path
        d="M6.73582 2.66797H1.66507C1.10028 2.66797 0.768847 3.30481 1.09288 3.76756C1.09703 3.77378 0.985128 3.62125 5.15613 9.299C5.34116 9.56734 5.28578 9.28028 5.28578 15.2673C5.28578 15.8687 5.97307 16.216 6.45897 15.8546C8.25697 14.4992 8.69385 14.3465 8.69385 13.6372C8.69385 9.45256 8.64007 9.565 8.8235 9.299L9.69385 8.11391C7.50378 7.31716 6.19113 5.00116 6.73582 2.66797Z"
        fill="#FF8293"
      />
      <path
        d="M8.81092 0.942691C6.4648 3.04613 7.60202 6.79738 10.5597 7.38175C12.9185 7.88013 15.0345 6.01091 15.0345 3.72722C15.0345 0.52116 11.2106 -1.184 8.81092 0.942691ZM12.515 4.28032C12.8123 4.57613 12.5998 5.08269 12.1836 5.08269C11.9332 5.08269 11.8704 4.96425 11.2942 4.39025C10.7297 4.95225 10.6607 5.08269 10.4044 5.08269C9.98727 5.08269 9.77745 4.57444 10.0731 4.28032L10.6284 3.72722L10.0731 3.17413C9.63223 2.73553 10.2963 2.07032 10.7361 2.5086L11.2942 3.06419L11.852 2.5086C12.2918 2.07032 12.956 2.73528 12.515 3.17413L11.9597 3.72722L12.515 4.28032Z"
        fill="#FF8293"
      />
    </g>
    <defs>
      <clipPath id="clip0_1488_167524">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
