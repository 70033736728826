import { CheckBox } from 'components/checkbox';
import { DataTable } from 'components/data-table';
import { EditableCell } from 'components/editable-cell';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import type {
  FleetTire,
  FleetTiresReplacements
} from 'models/fleet-tires-replacements';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { dateTableFormat, dateToHeaderFormat } from 'helpers/date';
import { NoData } from 'components/no-data';
import {
  getFleetTiresReplacementsAction,
  updateFleetTiresReplacementsAction
} from 'store/fleet-tires-replacements/actions';
import type { ID, OptionItem } from 'common/types';
import { TableSelect } from 'components/table-select';
import { FleetTiresActionList, PAGE_SIZE } from 'common/constants';
import { UpdatedAt } from 'components/updated-at';
import { Loader } from 'components/loader';
import { updateNotif } from 'store/notifications/reducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { HeaderBtn } from 'components/header-btn';
import { AppRoute } from 'common/enums/app/app-route';
import { updateFleetReplacement } from 'store/fleet-tires-replacements/reducer';
import { SelectServerData } from 'components/select-server-data';
import { getTiresStoragesApi } from 'api/directories';
import { useGetInfiniteOptions } from 'hooks/use-get-infinite-options';
import { updateFleetTiresReplacementsApi } from 'api/fleet-tires-replacements';
import { toast } from 'react-toastify';
import { tableHeaders } from './data';
import type { FleetReplacementsTable } from './data';
import { Recipient } from './recipient';

export const FleetTiresReplacementsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);
  const [filters, setFilters] = useState<Record<string, string | boolean>>({});

  const tiresStorageData = useGetInfiniteOptions({
    fetcher: getTiresStoragesApi
  });

  const { fleetReplacements, isLoading, error } = useTypedSelector(
    (state) => state.fleetTiresReplacements
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(
      getFleetTiresReplacementsAction(
        `?page=${page || 1}&size=${PAGE_SIZE}&search_plate=${
          filters.vehicle_plate || ''
        }&need_replacement=${filters.need_replacement || ''}`
      )
    );
    dispatch(updateNotif({ fleetReplacements: false }));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams, filters]);

  useEffect(() => {
    if (error) {
      toast(error, { type: 'error' });
    }
  }, [error]);

  const handleReplacementConfirmation = ({
    vehicleId,
    currentTire,
    value
  }: {
    vehicleId: ID;
    currentTire: FleetTire;
    value: boolean;
  }) => {
    dispatch(
      updateFleetReplacement({
        vehicleId,
        tire: { ...currentTire, replacement_confirmation: value }
      })
    );
    updateFleetTiresReplacementsApi({
      id: vehicleId,
      body: {
        tire_data: { id: currentTire.id, replacement_confirmation: value }
      }
    }).catch(console.log);
  };

  const handleSelect = useCallback(
    ({
      key,
      vehicleId,
      currentTire,
      value
    }: {
      key: string;
      vehicleId: ID;
      currentTire: FleetTire;
      value: OptionItem;
    }) => {
      const fieldsToUpdate: Record<string, OptionItem | string> = {
        [key]: value
      };
      if (key === 'action' && value.id !== 2) {
        fieldsToUpdate.recipient_data = '';
      }
      dispatch(
        updateFleetReplacement({
          vehicleId,
          tire: { ...currentTire, ...fieldsToUpdate }
        })
      );
      updateFleetTiresReplacementsApi({
        id: vehicleId,
        body: { tire_data: { id: currentTire.id, [key]: value.id } }
      }).catch(console.log);
    },
    [dispatch]
  );

  const handleConfirm = ({
    vehicleId,
    tire
  }: {
    vehicleId: ID;
    tire: FleetTire;
  }) => {
    dispatch(
      updateFleetTiresReplacementsAction({
        id: vehicleId,
        body: {
          tire_data: {
            id: tire.id,
            confirmed: true,
            recipient_data: tire.recipient_data,
            tire_storage: tire.tire_storage?.id,
            action: tire.action.id,
            replacement_confirmation: tire.replacement_confirmation
          }
        }
      })
    );
  };

  const transformToTableFormat = (
    replacements: FleetTiresReplacements[]
  ): FleetReplacementsTable[] =>
    replacements.flatMap((vehicle) => [
      {
        id: `vehicle_${vehicle.id}`,
        vehicle_plate: vehicle.vehicle_plate,
        vehicle_brand_model: vehicle.vehicle_brand_model,
        tire_model: '',
        season: '',
        size: '',
        diameter: '',
        status: '',
        defect: '',
        tread_depth: '',
        replacement_confirmation: '',
        tire_storage: '',
        fact_tire_storage: '',
        paid_storage: '',
        action: '',
        recipient_data: '',
        confirmed: '',
        confirmed_at: ''
      },
      ...vehicle.tire_replacements.map((item) => ({
        ...item,
        vehicle_plate: '',
        vehicle_brand_model: '',
        recipient_data:
          item.action.id === 2 ? (
            <Recipient
              disabled={item.confirmed}
              value={item.recipient_data}
              currentTire={item}
              vehicleId={vehicle.id}
              id={item.id}
            />
          ) : (
            ''
          ),
        tire_storage: (
          <SelectServerData
            disabled={item.confirmed}
            initialValue={item.tire_storage}
            onSelect={(value) =>
              handleSelect({
                key: 'tire_storage',
                vehicleId: vehicle.id,
                value,
                currentTire: item
              })
            }
            options={tiresStorageData.options}
            searchTerm={tiresStorageData.search.term}
            onChangeSearch={tiresStorageData.search.onChangeSearch}
            setLimit={tiresStorageData.setLimit}
            hasMoreOptions={tiresStorageData.hasMoreOptions}
            isLoading={tiresStorageData.isLoading}
          />
        ),
        paid_storage: item.paid_storage ? 'Так' : 'Ні',
        confirmed_at: dateTableFormat(item.confirmed_at),
        action: (
          <TableSelect
            disabled={item.confirmed}
            options={FleetTiresActionList}
            initialValue={item.action}
            onSelect={(value) =>
              handleSelect({
                key: 'action',
                vehicleId: vehicle.id,
                value,
                currentTire: item
              })
            }
          />
        ),
        fact_tire_storage: item.fact_tire_storage?.title,
        replacement_confirmation: (
          <EditableCell
            disabled={item.confirmed || item.status === "заказ обов'язковий"}
          >
            <CheckBox
              checked={item.replacement_confirmation}
              setChecked={(checked) =>
                handleReplacementConfirmation({
                  vehicleId: vehicle.id,
                  currentTire: item,
                  value: checked
                })
              }
              disabled={item.confirmed || item.status === "заказ обов'язковий"}
            />
          </EditableCell>
        ),
        confirmed: (
          <EditableCell disabled={item.confirmed}>
            <CheckBox
              checked={item.confirmed}
              disabled={item.confirmed}
              setChecked={() =>
                handleConfirm({ vehicleId: vehicle.id, tire: item })
              }
            />
          </EditableCell>
        )
      }))
    ]);

  return (
    <>
      <Navbar />
      <Subheader title="Заміна / Утилізація">
        {!!fleetUpdates.results.length &&
          !!fleetReplacements.results.length && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                fleetUpdates.results[0].fleet_replacements_table
              )}
            </UpdatedAt>
          )}
        <HeaderBtn
          onClick={() => navigate(AppRoute.FLEET_REPLACEMENTS_HISTORY)}
        >
          Переглянути історію
        </HeaderBtn>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {fleetReplacements.results.length || Object.keys(filters).length ? (
          <DataTable<FleetReplacementsTable>
            headers={tableHeaders}
            data={transformToTableFormat(fleetReplacements.results)}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              fleetReplacements.count >= PAGE_SIZE
                ? scrollHeight - 64
                : scrollHeight
            }
            stickyColumns={2}
            count={fleetReplacements.count}
            isFetching={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
