import styled from 'styled-components';

export const EditableCellStyled = styled.div<{
  $disabled?: boolean;
  $withOutPaddingTop?: boolean;
  $invalid?: boolean;
  $isTouched?: boolean;
}>`
  height: 100%;
  background: ${(p) =>
    p.$disabled
      ? p.theme.colors.white
      : p.$isTouched
      ? p.theme.colors.coloumnHover
      : p.theme.colors.coloumn};
  ${(p) => (p.$invalid ? `background: ${p.theme.colors.lightError};` : '')}
  padding: ${(p) => (p.$withOutPaddingTop ? '0 16px' : '0 16px')};
  display: flex;
  align-items: center;
`;
