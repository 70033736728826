import { DataTable } from 'components/data-table';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { NoData } from 'components/no-data';
import {
  confirmedUpdateFleetVehicleAction,
  getFleetVehicleAction
} from 'store/fleet-vehicle/actions';
import type { FleetVehicle } from 'models/fleet-vehicle';
import { PAGE_SIZE } from 'common/constants';
import type { OptionItem } from 'common/types';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { Loader } from 'components/loader';
import { updateNotif } from 'store/notifications/reducer';
import { useSearchParams } from 'react-router-dom';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { InputPhone } from 'components/input-phone';
import { SelectServerData } from 'components/select-server-data';
import { useGetInfiniteOptions } from 'hooks/use-get-infinite-options';
import { getLocationsApi, getTiresStoragesApi } from 'api/directories';
import { ConfirmPanel } from 'components/confirm-panel';
import { validatePhone } from 'helpers/validate-phone';
import { ConfirmPopup } from 'components/confirm-popup';
import { toast } from 'react-toastify';
import { getApiError } from 'helpers/get-api-error';
import type { FleetVehicleTable } from './data';
import { tableHeaders } from './data';
import { DriverName } from './driver-name';

export const FleetVehiclePage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);

  const [touchedVehicles, setTouchedVehicles] = useState<
    Record<
      string,
      {
        driver_first_name?: string;
        driver_last_name?: string;
        driver_phone?: string;
        vehicle_location?: OptionItem;
        tires_storage_city?: OptionItem;
        isValid?: boolean;
      }
    >
  >({});
  const [filters, setFilters] = useState<Record<string, string | boolean>>({});
  const [sortBy, setSortBy] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const tiresStorageData = useGetInfiniteOptions({
    fetcher: getTiresStoragesApi
  });

  const tiresLocationsData = useGetInfiniteOptions({
    fetcher: getLocationsApi
  });

  const { fleetVehicle, isLoading, error } = useTypedSelector(
    (state) => state.fleetVehicle
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(
      getFleetVehicleAction(
        `?page=${page || 1}&size=${PAGE_SIZE}&vehicle_brand_model=${
          filters.vehicle_brand_model || ''
        }&vehicle_plate=${filters.vehicle_plate || ''}&driver_last_name=${
          filters.driver_last_name || ''
        }&driver_first_name=${
          filters.driver_first_name || ''
        }&search_driver_phone=${filters.driver_phone || ''}&location_id=${
          filters.vehicle_location || ''
        }&tire_storage_id=${filters.tires_storage_city || ''}&sort_by=${sortBy}`
      )
    );
    dispatch(updateNotif({ fleetVehicle: false }));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams, filters, sortBy]);

  useEffect(() => {
    if (error) {
      toast(error, { type: 'error' });
    }
  }, [error]);

  const handleSelect = useCallback(
    ({
      key,
      currentVehicle,
      value
    }: {
      key: string;
      currentVehicle: FleetVehicle;
      value: OptionItem;
    }) => {
      setTouchedVehicles((state) => {
        const fields = {
          driver_first_name:
            state[currentVehicle.id]?.driver_first_name ??
            currentVehicle.driver_first_name,
          driver_last_name:
            state[currentVehicle.id]?.driver_last_name ??
            currentVehicle.driver_last_name,
          driver_phone: validatePhone(
            state[currentVehicle.id]?.driver_phone?.replace('+', '') ??
              currentVehicle.driver_phone
          ),
          vehicle_location: value.id
        };

        return {
          ...state,
          [currentVehicle.id]: {
            ...(state[currentVehicle.id] || {}),
            [key]: value,
            isValid: Object.values(fields).every((value) => value)
          }
        };
      });
    },
    []
  );

  const handleChangeTextFields = ({
    key,
    value,
    currentVehicle
  }: {
    key: string;
    value: string;
    currentVehicle: FleetVehicle;
  }) => {
    setTouchedVehicles((state) => {
      const fields = {
        driver_first_name:
          state[currentVehicle.id]?.driver_first_name ??
          currentVehicle.driver_first_name,
        driver_last_name:
          state[currentVehicle.id]?.driver_last_name ??
          currentVehicle.driver_last_name,
        driver_phone: validatePhone(
          state[currentVehicle.id]?.driver_phone?.replace('+', '') ??
            currentVehicle.driver_phone
        ),
        vehicle_location:
          state[currentVehicle.id]?.vehicle_location?.id ??
          currentVehicle.vehicle_location?.id,
        [key]:
          key === 'driver_phone'
            ? validatePhone(value?.replace('+', ''))
            : value
      };
      const stateEntries = Object.entries({
        ...(state[currentVehicle.id] || {}),
        [key]: value
      });
      const isNotTouched = stateEntries
        .filter(([key]) => key !== 'isValid')
        .every(
          ([key, value]) => currentVehicle[key as keyof FleetVehicle] === value
        );

      if (isNotTouched) {
        const data = { ...state };
        if (data[currentVehicle.id]) {
          delete data[currentVehicle.id];
        }

        return data;
      }

      return {
        ...state,
        [currentVehicle.id]: {
          ...(state[currentVehicle.id] || {}),
          [key]: value,
          isValid: Object.values(fields).every((value) => value)
        }
      };
    });
  };

  const handleSubmit = () => {
    setShowConfirm(false);
    dispatch(
      confirmedUpdateFleetVehicleAction({
        body: Object.entries(touchedVehicles).map(([id, data]) => ({
          id,
          driver_first_name: data.driver_first_name,
          driver_last_name: data.driver_last_name,
          driver_phone: data.driver_phone && `+${data.driver_phone}`,
          location: data.vehicle_location?.id,
          tire_storage: data.tires_storage_city?.id
        }))
      })
    )
      .unwrap()
      .then(() => {
        setTouchedVehicles({});
        const page = searchParams.get('page');
        dispatch(
          getFleetVehicleAction(
            `?page=${page || 1}&size=${PAGE_SIZE}&vehicle_brand_model=${
              filters.vehicle_brand_model || ''
            }&vehicle_plate=${filters.vehicle_plate || ''}&driver_last_name=${
              filters.driver_last_name || ''
            }&driver_first_name=${
              filters.driver_first_name || ''
            }&search_driver_phone=${filters.driver_phone || ''}&location_id=${
              filters.vehicle_location || ''
            }&tire_storage_id=${
              filters.tires_storage_city || ''
            }&sort_by=${sortBy}`
          )
        );
      })
      .catch((err) => {
        toast(getApiError(err), { type: 'error' });
      });
  };

  const isValidForm = Object.values(touchedVehicles).every(
    (item) => item.isValid
  );

  const transformToTableFormat = (vehicles: FleetVehicle[]) =>
    vehicles.map((item) => ({
      ...item,
      driver_last_name: (
        <DriverName
          field="driver_last_name"
          id={item.id}
          value={
            touchedVehicles[item.id]?.driver_last_name ?? item.driver_last_name
          }
          setValue={(value) =>
            handleChangeTextFields({
              key: 'driver_last_name',
              currentVehicle: item,
              value
            })
          }
          isTouched={
            typeof touchedVehicles[item.id]?.driver_last_name === 'string'
          }
          validate={!!touchedVehicles[item.id]}
        />
      ),
      driver_first_name: (
        <DriverName
          field="driver_first_name"
          id={item.id}
          value={
            touchedVehicles[item.id]?.driver_first_name ??
            item.driver_first_name
          }
          setValue={(value) =>
            handleChangeTextFields({
              key: 'driver_first_name',
              currentVehicle: item,
              value
            })
          }
          isTouched={
            typeof touchedVehicles[item.id]?.driver_first_name === 'string'
          }
          validate={!!touchedVehicles[item.id]}
        />
      ),
      driver_phone: (
        <InputPhone
          value={touchedVehicles[item.id]?.driver_phone ?? item.driver_phone}
          setValue={(value) => {
            handleChangeTextFields({
              key: 'driver_phone',
              currentVehicle: item,
              value
            });
          }}
          isTouched={typeof touchedVehicles[item.id]?.driver_phone === 'string'}
          validate={!!touchedVehicles[item.id]}
        />
      ),
      vehicle_location: (
        <SelectServerData
          initialValue={
            touchedVehicles[item.id]?.vehicle_location ?? item.vehicle_location
          }
          onSelect={(value) =>
            handleSelect({
              key: 'vehicle_location',
              value,
              currentVehicle: item
            })
          }
          options={tiresLocationsData.options}
          searchTerm={tiresLocationsData.search.term}
          onChangeSearch={tiresLocationsData.search.onChangeSearch}
          setLimit={tiresLocationsData.setLimit}
          hasMoreOptions={tiresLocationsData.hasMoreOptions}
          isLoading={tiresLocationsData.isLoading}
          validate={!!touchedVehicles[item.id]}
          isTouched={!!touchedVehicles[item.id]?.vehicle_location}
        />
      ),
      tires_storage_city: (
        <SelectServerData
          initialValue={
            touchedVehicles[item.id]?.tires_storage_city ??
            item.tires_storage_city
          }
          onSelect={(value) =>
            handleSelect({
              key: 'tires_storage_city',
              value,
              currentVehicle: item
            })
          }
          options={tiresStorageData.options}
          searchTerm={tiresStorageData.search.term}
          onChangeSearch={tiresStorageData.search.onChangeSearch}
          setLimit={tiresStorageData.setLimit}
          hasMoreOptions={tiresStorageData.hasMoreOptions}
          isLoading={tiresStorageData.isLoading}
          isTouched={!!touchedVehicles[item.id]?.tires_storage_city}
        />
      )
    }));

  return (
    <>
      <Navbar />
      <Subheader title="Авто">
        {!!fleetUpdates.results.length && !!fleetVehicle.results.length && (
          <UpdatedAt absoluteAlign>
            {dateToHeaderFormat(fleetUpdates.results[0].fleet_vehicle_table)}
          </UpdatedAt>
        )}
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {fleetVehicle.results.length || Object.keys(filters).length ? (
          <div>
            <DataTable<FleetVehicleTable>
              headers={tableHeaders}
              data={transformToTableFormat(fleetVehicle.results)}
              checkedList={[]}
              setCheckedList={() => {}}
              actions={[]}
              withOutCheck
              customHeight={scrollHeight - 64}
              count={fleetVehicle.count}
              isFetching={isLoading}
              filters={filters}
              setFilters={setFilters}
              withConfirmBtn
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </div>
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
      <ConfirmPanel
        disabled={
          !isValidForm || !Object.values(touchedVehicles).length || isLoading
        }
        hasPagination={fleetVehicle.count > PAGE_SIZE}
        onClick={() => setShowConfirm(true)}
      />
      <ConfirmPopup
        show={showConfirm}
        setShow={setShowConfirm}
        onSubmit={handleSubmit}
        title="Чи дійсно бажаєте підтвердити зміни?"
      />
    </>
  );
};
