import { PAGE_SIZE } from 'common/constants';
import { AppRoute } from 'common/enums/app/app-route';
import { HistoryContainer } from 'components/history-container';
import { HistoryItem } from 'components/history-item';
import { Loader } from 'components/loader';
import { Navbar } from 'components/navbar';
import { NoData } from 'components/no-data';
import { Pagination } from 'components/paginations';
import { Subheader } from 'components/subheader';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { getFleetReplacementsHistoryAction } from 'store/fleet-replacements-history/actions';
import { useAppDispatch, useTypedSelector } from 'store/store';

export const FleetReplacementsHistoryPage = () => {
  const dispatch = useAppDispatch();
  const { fleetReplacementsHistory, isLoading } = useTypedSelector(
    (state) => state.fleetReplacementsHistory
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getFleetReplacementsHistoryAction(`?page=${page || 1}`));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams]);

  return (
    <>
      <Navbar></Navbar>
      <Subheader title="Історія" backRoute={AppRoute.ROOT}>
        {!!fleetUpdates.results.length &&
          !!fleetReplacementsHistory.results.length && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                fleetUpdates.results[0].fleet_replacements_table
              )}
            </UpdatedAt>
          )}
      </Subheader>
      {isLoading ? (
        <Loader isBig position="static" />
      ) : fleetReplacementsHistory.results.length ? (
        <HistoryContainer
          withPagination={fleetReplacementsHistory.count > PAGE_SIZE}
        >
          {fleetReplacementsHistory.results.map((item, i) => (
            <HistoryItem
              key={i}
              title={item.history_message}
              date={item.confirmed_at}
            />
          ))}
        </HistoryContainer>
      ) : (
        <NoData height="calc(100vh - 162px)" />
      )}
      {fleetReplacementsHistory.count > PAGE_SIZE ? (
        <Pagination count={fleetReplacementsHistory.count} />
      ) : (
        ''
      )}
    </>
  );
};
