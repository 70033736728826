import styled from 'styled-components';

export const TableSelectWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TableSelectStyled = styled.div<{
  $isOpen?: boolean;
  $disabled?: boolean;
  $withoutBg?: boolean;
  $invalid?: boolean;
  $isTouched?: boolean;
}>`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.secondaryDark};
  width: 100%;
  height: 100%;
  background: ${(p) =>
    p.$disabled
      ? p.theme.colors.white
      : p.$isOpen || p.$isTouched
      ? p.theme.colors.coloumnHover
      : p.$withoutBg
      ? p.theme.colors.white
      : p.theme.colors.coloumn};
  ${(p) => (p.$invalid ? `background: ${p.theme.colors.lightError};` : '')}
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  svg {
    min-width: 16px;
    transform: ${(p) => (p.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
  pointer-events: ${(p) => (p.$disabled ? 'none' : 'unset')};
`;
