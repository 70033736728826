import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FleetVehicle } from 'models/fleet-vehicle';
import type { ResponseWithPagination } from 'common/types';
import { getApiError } from 'helpers/get-api-error';
import {
  confirmedUpdateFleetVehicleAction,
  getFleetVehicleAction,
  updateFleetVehicleAction
} from './actions';

interface FleetVehicleState {
  fleetVehicle: ResponseWithPagination<FleetVehicle>;
  isLoading: boolean;
  error: string;
}

const initialState: FleetVehicleState = {
  fleetVehicle: { count: 0, next: null, previous: null, results: [] },
  isLoading: false,
  error: ''
};

const fleetVehicleSlice = createSlice({
  name: 'fleet-vehicle',
  initialState,
  reducers: {
    updateFleetVehicle: (
      state,
      { payload }: PayloadAction<Partial<FleetVehicle>>
    ) => {
      state.fleetVehicle.results = state.fleetVehicle.results.map((item) =>
        item.id === payload.id ? { ...item, ...payload, touched: true } : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFleetVehicleAction.fulfilled, (state, { payload }) => {
      state.fleetVehicle = payload;
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(getFleetVehicleAction.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getFleetVehicleAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = getApiError(action.error.message);
    });
    builder.addCase(
      updateFleetVehicleAction.fulfilled,
      (state, { payload }) => {
        state.fleetVehicle.results = state.fleetVehicle.results.map((item) =>
          item.id === payload.id ? payload : item
        );
      }
    );
    builder.addCase(confirmedUpdateFleetVehicleAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmedUpdateFleetVehicleAction.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(confirmedUpdateFleetVehicleAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const { updateFleetVehicle } = fleetVehicleSlice.actions;
export const fleetVehicleReducer = fleetVehicleSlice.reducer;
